<template>
  <nuxt-link :to="props.to" :target="props.target" :class="props.class" :style="props.style" :id="props.id" :data-text="props.dataText" @click="click">
    <slot></slot>
  </nuxt-link>
</template>
<script setup>

const props = defineProps({
  to: String,
  target: String,
  class: String,
  style: String,
  id: String,
  dataText: String,
});

const emit = defineEmits(['click']);

const click = () => {
  emit('click');

  if (props.to === document.location.pathname) {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}

</script>